import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { handleAPICall, checkStatusCode, createToken } from './APIUtils'

const initialState = {
    categories: [],
    ideas: [],
    currentIdea: {},
    currentIdeaContent: {},
    listCategoriesLoading: false,
    listCategoriesError: "",
    listIdeasLoading: false,
    listIdeasError: "",
    createIdeaLoading: false,
    createIdeaError: "",
    createCategoryLoading: false,
    createCategoryError: "",
    updateIdeaLoading: false,
    updateIdeaError: "",
    updateCategoryLoading: false,
    updateCategoryError: "",
    createIdeaContentLoading: false,
    createIdeaContentError: "",
    updateIdeaContentLoading: false,
    updateIdeaContentError: ""
}

export const listAllCategoriesAPI = createAsyncThunk("categories/list", async (_, thunkAPI) => {
    try {
        const data = await handleAPICall(
            'encouragementAPI',
            '/encouragement/categories?limit=9999999999',
            {},
            'GET'
        );
        if (data.error && Object.keys(data.error).length>0) {
            try {
                let msg = data.error.error.response.data.error.message;
                return thunkAPI.rejectWithValue(msg);
            } catch (error) {
                return thunkAPI.rejectWithValue('Something went wrong!');
            }
        }
        return data.success.data.items;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateCategoryAPI = createAsyncThunk("categories/update", async ({body, callback = () => { }}, thunkAPI) => {
    try {
        const catId = body?.category_id;
        delete body.category_id;

        const res = await handleAPICall(
            'encouragementAPI',
            `/encouragement/categories/${catId}`,
            { body },
            'UPDATE'
        )
        if (res.errors && res.errors.length>0) {
            thunkAPI.rejectWithValue(res.errors[0] || "Something went wrong!");
        }
        return { categoryId: catId, data: res.success.data }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const listAllIdeasAPI = createAsyncThunk("ideas/list", async(_, thunkAPI) => {
    try {
        const data = await handleAPICall(
            'encouragementAPI',
            '/encouragement/ideas?limit=9999999999?is_active=true',
            {},
            'GET'
        );
        
        if (data.error && Object.keys(data.error).length>0) {
            try {
                let msg = data.error.error.response.data.error.message;
                return thunkAPI.rejectWithValue(msg);
            } catch (error) {
                return thunkAPI.rejectWithValue('Something went wrong!');
            }
        }
        console.log("DATA FROM IDEAS", data.success.data.items);
        
        return data.success.data.items;
    } catch (error) {
        console.log("ERRRRR",error);
        
        return thunkAPI.rejectWithValue(error);
    }
})

export const createIdeasAPI = createAsyncThunk("ideas/create", async ({ body, callback = () => {} }, thunkAPI) => {
    try {
        const res = await handleAPICall(
            'encouragementAPI',
            '/encouragement/ideas',
            { body },
            'POST'
        )
        if (res.errors && res.errors.length>0) {
            return thunkAPI.rejectWithValue(res.errors[0]);
        }
        callback(res);
        return { data: res.success.data }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateIdeasAPI = createAsyncThunk("ideas/update", async ({ body, contentBody, callback = () => {} }, thunkAPI) => {
    try {
        const id = body.idea_id;
        delete body.idea_id;

        const res = await handleAPICall(
            'encouragementAPI',
            `/encouragement/ideas/${id}`,
            { body },
            'UPDATE'
        )

        if (res.errors && res.errors.length > 0) {
            return thunkAPI.rejectWithValue(res.errors[0]);
        }
        const payload = {
            "is_active": true,
            "contentUpdates": { ...contentBody }
        }
        const res2 = await handleAPICall(
            'encouragementAPI',
            `/encouragement/ideasContent/${id}`,
            { body: payload },
            'UPDATE'
        )
        if (res2.errors && res2.errors.length > 0) {
            return thunkAPI.rejectWithValue(res2.errors[0]);
        }
        callback(res2);
        return { data: res.success.data, content: res2.success.data,id: id }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateIdeasContentAPI = createAsyncThunk("ideasContent/update",async ({ body, activeFlag = true, idea_id, callback = () => {} }, thunkAPI) => {
    try {
        const payload = {
            "is_active" : activeFlag,
            "contentUpdates" : { ...body }
        }
        const res = await handleAPICall(
            'encouragementAPI',
            `/encouragement/ideasContent/${idea_id}`,
            { body: payload },
            'UPDATE'
        )
        if (res.errors && res.errors.length > 0) {
            return thunkAPI.rejectWithValue(res.errors[0] || "Something went wrong");
        }

        return { data: res.success.data, id: idea_id }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const bucketListSlice = createSlice({
    name: "Bucketlist",
    initialState,
    reducers : {
        setCurrentIdea: (state,action) => {
            state.currentIdea = action.payload;
        },
        setCurrentIdeaContent: (state, action) => {
            state.currentIdeaContent = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listAllCategoriesAPI.pending, (state, action) => {
            state.listCategoriesLoading = true;
            state.listCategoriesError = "";
        })
        builder.addCase(listAllCategoriesAPI.fulfilled, (state,action) => {
            state.categories = action.payload;
            state.listCategoriesLoading = false;
        })
        builder.addCase(listAllCategoriesAPI.rejected, (state, action) => {
            state.listCategoriesLoading = false;
            state.listCategoriesError = action.payload;
        })
        builder.addCase(listAllIdeasAPI.pending, (state,action) => {
            state.listIdeasLoading = true;
            state.listIdeasError = ""
        })
        builder.addCase(listAllIdeasAPI.fulfilled, (state, action) => {
            state.listIdeasLoading = false;
            state.ideas = action.payload;
            state.listIdeasError = ""
        })
        builder.addCase(listAllIdeasAPI.rejected, (state, action) => {
            state.listIdeasLoading = false;
            state.listIdeasError = `ERROR ${JSON.stringify(action.payload)}`;
        })
        builder.addCase(createIdeasAPI.pending, (state, action) => {
            state.createIdeaLoading = true;
            state.createIdeaError = action.payload;
        })
        builder.addCase(createIdeasAPI.fulfilled, (state, action) => {
            state.createIdeaLoading = false;
            const oldIdeas = [...state.ideas];
            oldIdeas.push(action.payload);
            state.ideas = oldIdeas;
        })
        builder.addCase(createIdeasAPI.rejected, (state, action) => {
            state.createIdeaLoading = false;
            state.createIdeaError = action.payload;
        })
        builder.addCase(updateIdeasAPI.pending, (state, action) => {
            state.updateIdeaLoading = true;
            state.updateIdeaError = "";
        })
        builder.addCase(updateIdeasAPI.fulfilled, (state, action) => {
            state.updateIdeaLoading = false;
            state.currentIdea = {};
            state.currentIdeaContent = {};
            const id = action.payload.id;
            const oldIdeas = [...state.ideas];
            const content = action.payload.content;
            const data = action.payload.data;
            try {
                for (let i = 0; i < oldIdeas.length; i++) {
                    const element = oldIdeas[i];
                    if (element?._idea_id === id) {
                        oldIdeas[i] = { ...data, content: content }
                        break;
                    }
                }
            } catch (error) {
                
            }

            state.ideas = oldIdeas;
        })

        builder.addCase(updateIdeasAPI.rejected, (state, action) => {
            state.updateIdeaLoading = false;
            state.updateIdeaError = action.payload;
        })

        builder.addCase(updateIdeasContentAPI.pending, (state,action) => {
            state.updateIdeaContentLoading = true;
            state.updateIdeaContentError = "";
        })
        builder.addCase(updateIdeasContentAPI.fulfilled, (state, action) => {
            state.updateIdeaContentLoading = false;
            state.currentIdea = {};
            state.currentIdeaContent = {};
            const id = action.payload.id;
            const oldIdeas = [...state.ideas];
            const data = action.payload.data;
            try {
                for (let i = 0; i < oldIdeas.length; i++) {
                    const element = oldIdeas[i];
                    if (element?._idea_id === id) {
                        element["content"] = data;
                        oldIdeas[i] = element;
                        break;
                    }
                }
            } catch (error) {

            }

            state.ideas = oldIdeas;
        })
        builder.addCase(updateIdeasContentAPI.rejected, (state, action) => {
            state.updateIdeaContentLoading = false;
            state.updateIdeaContentError = action.payload;
        })
    }
})
export const { setCurrentIdea, setCurrentIdeaContent } = bucketListSlice.actions;
export default bucketListSlice.reducer;