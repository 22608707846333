// @ts-nocheck
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import UploadIdeasFile from './UploadIdeasFile';
import ManualAddIdeas from './ManualAddIdeas';
import Modal from '../../../../../sharedComponents/Modal';
import InputField from '../../../../../sharedComponents/InputField/InputField';
import SelectMenu from '../../../../../sharedComponents/SelectMenu/SelectMenu';
import DropZone from '../../../../../sharedComponents/DropZone/DropZone';
import { classify } from '../../../../../common/Utility';
import { VoiceProfileDomainDescription } from 'aws-sdk/clients/chimesdkvoice';
import closeIcon from '../../../../../assets/images/cancel.svg';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { createIdeasAPI, updateIdeasAPI } from '../../../../../api/bucketlistSlice';

const AddIdeasModal = ({
  show,
  allExistingIdeas,
  allExistingCategories,
  setAllIdeas,
  closeModal,
  editFlag = false
}: {
  show: boolean;
  allExistingIdeas: Idea[];
  allExistingCategories: IdeaCategory[];
  setAllIdeas: (newList: Idea[]) => void;
  closeModal: () => void;
  editFlag?: boolean;
}) => {
  const [tabSelected, setTabSelected] = useState<'manualAdd' | 'fileUpload'>(
    'fileUpload'
  );
  const dispatch = useDispatch();
  const { categories, currentIdea, currentIdeaContent, createIdeaLoading, updateIdeaLoading } = useSelector(state => state.bucketList);
  const [categoriesInput, setCategoriesInput] = useState([{
    label: "Default",
    value: ""
  }])
  const [ideaImage, setIdeaImage] = useState([]);
  const [tripImage, setTripImage] = useState([]);
  const [exp1Image, setExp1Image] = useState([])
  const [exp2Image, setExp2Image] = useState([])
  const [exp3Image, setExp3Image] = useState([])
  const [itinearyImg, setItinearyImg] = useState([]);
  const [defaultIncl, setDefaultIncl] = useState("");
  const [defaultExcl, setDefaultExcl] = useState("");
  const [defaultCat, setDefaultCat] = useState("");

  const [itinearyRows, setItinearyRows] = useState(1);
  const [faqCount, setFaqCount] = useState(6);


  const [merchant, setMerchant] = useState("");
  const [tDateFlag, setTDateFlag] = useState(false);
  const [hasRec, setHasRec] = useState(false);
  const [tripName, setTripName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [itinearyContent, setItinearyContent] = useState(<></>);

  function processClose() {
    closeModal();
    setTabSelected('fileUpload');
  }

  function handleSelect(date) {
    console.log(date); // native Date object
  }

  function handleTripName(e) {
    const val = e.target.value;
    setTripName(val);
  }

  useEffect(() => {
    return () => {
      try {
        ideaImage.forEach(file => URL.revokeObjectURL(file.preview))
        tripImage.forEach(file => URL.revokeObjectURL(file.preview))
        exp1Image.forEach(file => URL.revokeObjectURL(file.preview))
        exp2Image.forEach(file => URL.revokeObjectURL(file.preview))
        exp3Image.forEach(file => URL.revokeObjectURL(file.preview))
        for (let i = 0; i < itinearyImg.length; i++) {
          const element = itinearyImg[i];
          itinearyImg[i].forEach(file => URL.revokeObjectURL(file.preview))
        }
      } catch (error) {
        
      }
    };
  }, [])

  useEffect(() => {
    if(editFlag && currentIdea) {
      if (currentIdea.idea_title) {
        setTripName(currentIdea.idea_title)
      }
      if (currentIdea.categories && currentIdea.categories?.length>0) {
        setDefaultCat(currentIdea?.categories[0]?.category_id);
      }
      if(currentIdea.merchant_id) {
        setMerchant(currentIdea.merchant_id)
      }
      if(currentIdea.content) {
        if (currentIdea.content.is_recommended) {
          setHasRec(true);
        } else {
          setHasRec(false);
        }
        if (currentIdea.content.travel_dates && currentIdea.content.travel_dates.length > 0) {
          setTDateFlag(true);
        }
        // console.log("INSIDE", editFlag, currentIdea.content.detailed_itinerary);
        const itn = currentIdea.content.detailed_itinerary;
        const obj = itn?.find(e => e?.day === -1);

        if (obj) {
          setItinearyRows(itn?.length - 1)
          const incl = obj?.inclusion?.join(",");
          const excl = obj?.exclusion?.join(",");
          setDefaultIncl(incl);
          setDefaultExcl(excl);
        } else {
          setItinearyRows(itn?.length)
        }
      }
    }
  }, [editFlag])



  useEffect(() => {
    if (merchant && categories && categories.length > 0) {
      let input = [];
      categories?.filter(e => (e.merchant_id === merchant && e.is_active))?.map((ele) => {
        input.push({
          label: ele?.category_name,
          value: `${ele?.category_id}`
        })
      })

      setCategoriesInput([...input]);
    }
  }, [merchant, categories])

  const extractDate = (range, type) => {
    try {
      const [startDate, endDate] = range.split("-"); // Split into start and end dates
      const dateToUse = type === 0 ? startDate : endDate; // Decide which date to pick
      const [day, month, year] = dateToUse.split("/"); // Split DD/MM/YYYY format
      const formattedDate = new Date(`${year}-${month}-${day}`) // Create a new Date object
        .toISOString()
        .split("T")[0]; // Format as YYYY-MM-DD
      return formattedDate;
    } catch (error) {
      return new Date() // Create a new Date object
        .toISOString()
        .split("T")[0]; // Format as YYYY-MM-DD;
    }
  };


  const callback = (data, flag = false) => {
    // setEditFlag(false);
    if (data.error && Object.keys(data.error).length > 0) {
      try {
        toast.error(`${data.error.error.response.data.error.message}`);
      } catch (error) {
        toast.error(`Error while ${flag ? "Editing" : "Creating"} Idea`);
      }
      return;
    }
    toast.success("Success");
    if (data.success.code) {
      // toast.success(`${data.success.message}`);
      window.location.reload();
      processClose()
    }
  }

  const submitFile = async (fileName, file) => {
    try {
      const env = process.env.REACT_APP_ENVIRONMENT;
      const url = env === "production" ? "https://ralfxpezni.execute-api.ap-south-1.amazonaws.com/burner/file/s3/upload" : "https://a2jb9iadgc.execute-api.ap-south-1.amazonaws.com/devtwo/file/s3/upload";


      const res = await axios.post(url, {
        "bucketType": "communication",
        "filePath": `bucketListImages/${env === "production" ? "burner" : "devtwo"}/${fileName}`,
        "fileType": file.type
      });

      if (res.status === 200) {
        console.log(res.data);

        const { data } = res.data.success[0];
        const { PresignedUrl, ObjectUrl } = data;
        const uploadImg = await axios.put(PresignedUrl, file);
        if (uploadImg.status === 200) {
          return ObjectUrl;
        } else {
          return null;
        }
      } else {
        return null;
      }

    } catch (error) {
      console.log("ERROR WHILE UPLOADING IMG", error);
      return null;
    }
  }


  const onSubmitForm = async (e) => {
    if (!show) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formElements = e.target.elements;
      const ideaKeys = [
        "idea_title",
        "idea_content",
        "idea_image_url",
        "voucher_code",
        "merchant_id",
        "offer_id",
        "idea_value",
        "idea_currency",
        "idea_currency_symbol",
        "is_active",
        "encouragement_user_id",
        "is_custom",
        "categories",
        "content"
      ];
      const contentKeys = [
        "title",
        "travel_dates",
        "duration",
        "image",
        "cost_per_person",
        "group_goal_code",
        "group_members_count",
        "customize_itinerary_link",
        "experiences",
        "detailed_itinerary",
        "faqs",
        "location",
        "extras",
        "influencers",
        "highlight",
        "places",
        "is_recommended",
        "skip_places",
        "favourite"
      ]

      let formData = {
        "idea_title": "",
        "idea_content": "",
        "idea_image_url": "",
        "voucher_code": "",
        "merchant_id": "",
        "offer_id": null,
        "idea_value": 0,
        "idea_currency": "INR",
        "idea_currency_symbol": "₹",
        "is_active": true,
        "encouragement_user_id": null,
        "is_custom": false,
        "categories": [],
        "content": {}
      };
      let contentData = {}
      const stDate = document.getElementById("fromDate")?.value;
      const endDate = document.getElementById("endDate")?.value;
      let exp = [{}, {}, {}]
      let itineary = [];
      let inclusions = [];
      let exclusions = [];
      const formatTripName = tripName.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-'); // Replace spaces with hyphens
      // console.log("Trip Name", tripName, "FORMATTED", formatTripName);
      
      let itinearyImagesLink = [];
      let exp1Link = "", exp2Link = "", exp3Link = "";
      let tripLinkImage, ideaLinkImage;

      let bgImageName = "";
      if(ideaImage && ideaImage.length>0) {
        try {
          const { type } = ideaImage[0];
          const fileType = type.split("/");
          if(fileType && fileType.length>1) {
            bgImageName = `background.${fileType[1]}`
          } else{
            bgImageName = `${ideaImage[0]?.name}`
          }
        } catch (error) {
          bgImageName = `${ideaImage[0]?.name}`
        }
      }

      // Check for editFlag and process accordingly
      if (editFlag) {
        // Check if tripImage exists, else use default
        tripLinkImage = tripImage.length > 0
          ? await submitFile(`${formatTripName}/${tripImage[0]?.name}`, tripImage[0])
          : currentIdea?.content?.image;

        // Check if ideaImage exists, else use default
        ideaLinkImage = ideaImage.length > 0
          ? await submitFile(`${formatTripName}/${bgImageName}`, ideaImage[0])
          : currentIdea?.idea_image_url;

        // Handle Itinerary Images
        if (itinearyImg.length > 0) {
          // console.log("ITINEARY ARR", itinearyImg);
          while(itinearyImagesLink.length < itinearyImg.length) {
            itinearyImagesLink.push("");
          }
          await Promise.all(
            itinearyImg.map(async (ele, id) => {
              const images = currentIdea?.content?.detailed_itinerary;
              const flag = images?.length>=id;
              
              // itinearyImagesLink[id] = images[id]?.image_url;
              try {
                let url = null;
                if(ele && ele.length>0) {
                  url = await submitFile(`${formatTripName}/itineary/${ele[0].name}`, ele[0])
                }
                // console.log("IMAGES "+id, url);
                itinearyImagesLink[id] = (url || (flag ? images[id]?.image_url : null));
              } catch (error) {
                itinearyImagesLink[id] = ((flag ? images[id]?.image_url : null));
              }
            })
          );
        } else {
          // Use existing URLs if no new images are provided
          itinearyImagesLink = currentIdea?.content?.detailed_itinerary.map((itinerary) => itinerary.image_url) || [];
        }

        // Handle Experience Images
        exp1Link = exp1Image.length > 0
          ? await submitFile(`${formatTripName}/exp/${exp1Image[0]?.name}`, exp1Image[0])
          : currentIdea?.content?.experiences[0]?.image;

        exp2Link = exp2Image.length > 0
          ? await submitFile(`${formatTripName}/exp/${exp2Image[0]?.name}`, exp2Image[0])
          : currentIdea?.content?.experiences[1]?.image;

        exp3Link = exp3Image.length > 0
          ? await submitFile(`${formatTripName}/exp/${exp3Image[0]?.name}`, exp3Image[0])
          : currentIdea?.content?.experiences[2]?.image;
      } else {
        // Regular flow for creating new records
        tripLinkImage = await submitFile(`${formatTripName}/${tripImage[0]?.name}`, tripImage[0]);
        ideaLinkImage = await submitFile(`${formatTripName}/${bgImageName}`, ideaImage[0]);
        while (itinearyImagesLink.length < itinearyImg.length) {
          itinearyImagesLink.push("");
        }
        await Promise.all(
          itinearyImg.map(async (ele, id) => {
            try {
              const url = await submitFile(`${formatTripName}/itineary/${ele[0].name}`, ele[0]);
              itinearyImagesLink[id] = url || null;
            } catch (error) {
              itinearyImagesLink.push(null);
            }
          })
        );

        exp1Link = await submitFile(`${formatTripName}/exp/${exp1Image[0]?.name}`, exp1Image[0]);
        // exp2Link = await submitFile(`${formatTripName}/exp/${exp2Image[0]?.name}`, exp2Image[0]);
        // exp3Link = await submitFile(`${formatTripName}/exp/${exp3Image[0]?.name}`, exp3Image[0]);
      }



      if (stDate && endDate && stDate.length > 0 && endDate.length > 0) {
        const s1 = stDate.split(" ") // split into ["2024-12-26", "2024-12-31"]
          .map(dateStr => {
            const [year, month, day] = dateStr.split("-");
            return `${day}/${month}/${year}`;
          })
          .join(" ")

        const s2 = endDate.split(" ") // split into ["2024-12-26", "2024-12-31"]
          .map(dateStr => {
            const [year, month, day] = dateStr.split("-");
            return `${day}/${month}/${year}`;
          })
          .join(" ")

        contentData["travel_dates"] = `${s1}-${s2}`
      }

      let faqs = {};

      // console.log("ITINEARY IMAGE LINKS", itinearyImagesLink)

      for (let i = 0; i < formElements.length; i++) {
        const ele = formElements[i];
        const name = ele.name;
        if (name.length > 0) {
          if (name === "inclusions") {
            inclusions = ele.value.split(",");
            continue;
          }
          if (name === "exclusions") {
            exclusions = ele.value.split(",");
            continue;
          }
          if (contentKeys.findIndex(e => e === name) !== -1) {
            if ((name === "cost_per_person" || name === "group_members_count")) {
              if ((ele.value && ele.value.length > 0)) {
                contentData[name] = Number(ele.value);
              } else {
                contentData[name] = 0;
              }

            } else {
              contentData[name] = ele.value;
            }

          } else if (ideaKeys.findIndex(e => e === name) !== -1) {
            if (name === "categories") {
              formData[name] = [ele.value];
              continue;
            }
            formData[name] = ele.value;
          }

          if (name === "content_title") {
            contentData["title"] = ele.value;
          }

          if (name.includes("faq") && ele.value.length > 0) {

            const [f, type, number] = name.split("_");
            let num = Number(number);
            if (number) {
              if (!faqs[number]) {
                faqs[number] = {
                  "question": "",
                  "answer": ""
                }
              }

              if (type === "q") {
                faqs[number]["question"] = ele.value;
              } else if (type === "a") {
                faqs[number]["answer"] = ele.value;
              }
            }
          }


          if (name === "idea_value") {
            contentData["cost_per_person"] = ele.value
          }

          if ((name.includes("exp1_title") || name.includes("exp1_desc")) && (ele.value.length>0)) {
            if (name.includes("title")) {
              exp[0]["title"] = ele.value;
            } else {
              exp[0]["description"] = ele.value;
            }

            exp[0]["image"] = exp1Link
          }

          if ((name.includes("exp2_title") || name.includes("exp2_desc")) && (ele.value.length > 0)) {
            if (name.includes("title")) {
              exp[1]["title"] = ele.value;
            } else {
              exp[1]["description"] = ele.value;
            }

            exp[1]["image"] = exp2Link
          }

          if ((name.includes("exp3_title") || name.includes("exp3_desc")) && (ele.value.length > 0)) {
            if (name.includes("title")) {
              exp[2]["title"] = ele.value;
            } else {
              exp[2]["description"] = ele.value;
            }

            exp[2]["image"] = exp3Link
          }

          if (name.includes("day")) {
            const [day, no, type] = name.split("_");
            if (no) {
              if (!itineary[no]) {
                itineary[no] = {
                  day: Number(no)
                }
              }
              itineary[no]["image_url"] = itinearyImagesLink[no - 1]
              if (type === "title") {
                itineary[no]["title"] = ele.value;
              } else {
                itineary[no]["activities"] = ele.value.split(".")?.filter(e => (e && e.length > 0));
              }
            }
          }

        }
      }

      itineary.push({
        day: -1,
        inclusion: inclusions,
        exclusion: exclusions,
        "image_url": itinearyImagesLink[0]
      })
      itineary = itineary.filter(item => item !== null)
      console.log(itineary);
      contentData["experiences"] = exp;
      contentData["is_recommended"] = hasRec;
      contentData["detailed_itinerary"] = itineary;
      contentData["faqs"] = Object.values(faqs);
      contentData["image"] = tripLinkImage;
      if(!contentData["title"] || (contentData["title"] && contentData["title"].length === 0)) {
        contentData["title"] = formData["idea_title"];
      }
      contentData["customize_itinerary_link"] = "https://chat.whatsapp.com/HeccccRC7cb445xAidrOdz"

      formData["idea_image_url"] = ideaLinkImage;
      if(!editFlag) {
        formData["content"] = contentData;
        dispatch(createIdeasAPI({ body: formData, callback }));
      } else {
        let defaultCategory = currentIdea?.categories[0]?.category_id;
        if(formData["categories"].length === 0) {
          formData["categories"] = [defaultCategory]
        } else if (formData["categories"][0]?.includes("Select an Option")) {
          formData["categories"] = [defaultCategory]
        }
        formData["idea_id"] = currentIdea?.idea_id;
        delete formData.content;
        delete formData.encouragement_user_id;
        // console.log("EDIT FORM DATA",  contentData);
        
        dispatch(updateIdeasAPI({ body: formData, contentBody: contentData, callback }));
      }
      console.log("FORM DATA", formData);
      setIsSubmitting(false);
      // console.log(formData);

      
    } catch (error) {
      console.log("FORM ERROR", error);
      setIsSubmitting(false);
    }
  }

  const onDropIdeaImage = (acceptedFiles) => {
    setIdeaImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }
  const onDropTripImage = (acceptedFiles) => {
    setTripImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }

  const onDropExp1Image = (acceptedFiles) => {
    setExp1Image(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }

  const onDropExp2Image = (acceptedFiles) => {
    setExp2Image(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }

  const onDropExp3Image = (acceptedFiles) => {
    setExp3Image(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }

  const merchantIdInput = [
    {
      label: "Akbar Travels",
      value: "akbartravels"
    },
    {
      label: "Holiday Tribe",
      value: "holidaytribe"
    },
  ]
  const selectionRange = {
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  }

  useEffect(() => {
    if(editFlag) {
      const len = currentIdea?.content?.detailed_itinerary?.length - 1;
      setItinearyRows(len);
    }
    if(itinearyRows && itinearyRows>0) {
      while (itinearyImg.length < itinearyRows) {
        itinearyImg.push([]);
      }
    }
    console.log("ROWS",itinearyRows);
    
    const content = Array.from({ length: itinearyRows }, (_, i) => {
      let len = 0;
      let val = ""
      let titleVal = ""
      if (editFlag) {
        len = currentIdea?.content?.detailed_itinerary?.length;
        console.log(`LENGTH: ${len}, ITINEARY ROWS: ${itinearyRows}`);
        if(defaultIncl && defaultIncl.length>0) {
          len--;
        }
        if (itinearyRows > len) {
          val = "";
          titleVal = ""
        } else {
          let activities = currentIdea?.content?.detailed_itinerary[i]?.activities;
          if (activities) {
            val = activities.join(".");
          }
          titleVal = currentIdea?.content?.detailed_itinerary[i]?.title
        }
      }
      var image = [];
      return (
        <React.Fragment>
          <div className='col-span-2'>
            <InputField
              name={`day_${i + 1}_title`}
              value={!editFlag ? "" : titleVal}
              label={`Day ${i + 1} Title`}
              req={true}
            />
          </div>
          <div className='col-span-2'>
            <label className='block text-sm font-medium text-gray-700'>
              Upload Day {i + 1} Image
            </label>
            <DropZone
              onDrop={(acceptedFiles) => {
                setItinearyImg(prev => {
                  // Create a copy of the current state
                  const newImgs = [...prev];

                  // Ensure the array is long enough
                  while (newImgs.length <= i) {
                    newImgs.push([]);
                  }

                  // Map the accepted files and attach a preview
                  newImgs[i] = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );
                  console.log("NEW IMG", newImgs)
                  image = newImgs[i];
                  return newImgs;
                });
              }}
              files={itinearyImg[i] || image}
            />
          </div>

          <div className='col-span-2'>
            <InputField
              name={`day_${i + 1}_activities`}
              value={!editFlag ? "" : val}
              placeholder="Use Full Stop(.) values(No spaces) Ex: Activity1.Activity2.Activity3"
              label={`Day ${i + 1} Activities`}
              req={true}
            />
          </div>
        </React.Fragment>
      )
    })

    setItinearyContent(content);
  }, [itinearyRows, itinearyImg, currentIdeaContent, currentIdea])

  return (
    <Modal show={show} onClose={processClose} className={classify(['p-8'])}>
      <div className="flex justify-end">
        <button className='outline-none border-none' onClick={processClose}>
          <img className='w-9 h-9' src={closeIcon} alt="close" />
        </button>
      </div>
      <div className='container flex flex-col items-center px-4'>
        <h1 className='text-[24px]'>
          {editFlag ? 'Edit Idea' : 'Create Idea'}
        </h1>

        <form onSubmit={onSubmitForm} className='flex w-full flex-col items-center'>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              name='idea_title'
              value={!editFlag ? "" : currentIdea?.idea_title}
              onChangeHandler={handleTripName}
              label='Trip Title'
              req
            />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              name='content_title'
              value={!editFlag ? "" : currentIdea?.content?.title}
              label='Hero Banner Title'
              req
            />
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 w-full md:w-4/5">
            <div className='w-full md:w-full'>
              <label className="block mb-1 text-sm font-medium text-gray-900">
                Idea Content
                <span className="text-red-700">
                  *
                </span>
              </label>
              <textarea
                placeholder='Add Something nice...'
                name="idea_content"
                id="idea-content"
                cols="auto"
                rows="10"
                className='bg-white w-full border border-inputBorder shadow-sidebarActive rounded-md outline-none p-3 text-sm'
              >
                {!editFlag ? "" : currentIdea?.idea_content}
              </textarea>
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label className='block text-sm font-medium text-gray-700'>
              Upload Trip Central Image
            </label>
            <DropZone onDrop={onDropIdeaImage} files={ideaImage} />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <SelectMenu setValue={setMerchant} req={false} label='Merchant' name="merchant_id" value={editFlag && currentIdea?.merchant_id} options={merchantIdInput} />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField req={false} value={!editFlag ? "" : currentIdea?.idea_value} type='number' min={0} name='idea_value' label="Cost Per Person" />
          </div>
          {(merchant && merchant.length > 0) && (
            <div className='mt-4 w-full md:w-4/5'>
              <SelectMenu req={true} label='Categories' name="categories" value={editFlag ? defaultCat : ""} options={categoriesInput} />
            </div>
          )}

          <h3 className='text-[20px] my-4'>Trip Content</h3>

          <div className='grid grid-cols-2 gap-6 w-full md:w-4/5'>
            <div className="col-span-2 flex w-4/5 items-center h-10 mt-4">
              <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox"
                name=""
                defaultChecked={tDateFlag}
                onChange={(e) => setTDateFlag(e.target.checked)}
                id="hasVoucher"
                value="true"
              />
              <div className='text-sm ml-3'>
                Travel Dates Available?
              </div>
            </div>
            <div className="col-span-2">
              <InputField
                name='duration'
                value={!editFlag ? "" : currentIdea?.content?.duration}
                label='Duration'
                req={true}
              />
            </div>
            {tDateFlag && (
              <>
                <div className="col-span-1">
                  <label className='block text-sm font-medium text-gray-700'>
                    Travel Starting Date
                  </label>
                  <div className='relative mt-1 rounded-md shadow-sidebarActive w-full'>
                    <input
                      min={new Date().toISOString().split("T")[0]}
                      type="date"
                      defaultValue={editFlag ? extractDate(currentIdea?.content?.travel_dates, 0) : ""}
                      className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm"
                      name=""
                      id="fromDate" />
                  </div>

                </div>

                <div className="col-span-1">
                  <label className='block text-sm font-medium text-gray-700'>
                    Travel Ending Date
                  </label>
                  <div className='relative mt-1 rounded-md shadow-sidebarActive w-full'>
                    <input
                      min={new Date().toISOString().split("T")[0]}
                      type="date"
                      defaultValue={editFlag ? extractDate(currentIdea?.content?.travel_dates, 1) : ""}
                      className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm"
                      name=""
                      id="endDate" />
                  </div>

                </div>
              </>
            )}
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label className='block text-sm font-medium text-gray-700'>
              Upload Itineary Page Image
            </label>
            <DropZone onDrop={onDropTripImage} files={tripImage} />
          </div>

          <div className='grid grid-cols-2 gap-6 w-full md:w-4/5 mt-4'>
            <div className="col-span-1">
              <InputField
                name='group_goal_code'
                value={!editFlag ? "" : currentIdea?.content?.group_goal_code}
                label='Group Goal Code'
                req={false}
              />
            </div>
            <div className="col-span-1">
              <InputField req={false} value={!editFlag ? "" : currentIdea?.content?.group_members_count} type='number' min={0} name='group_members_count' label="Trip Members Count" />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-6 w-full md:w-4/5 mt-4'>
            <div className="col-span-2">
              <InputField
                name='location'
                value={!editFlag ? "" : currentIdea?.content?.location}
                label='Location'
                req={false}
              />
            </div>
            <div className="col-span-2 flex w-4/5 items-center h-10 mt-4">
              <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox"
                name=""
                defaultChecked={hasRec}
                onChange={(e) => setHasRec(e.target.checked)}
                id="hasInfluencerRecommended"
                value="true"
              />
              <div className='text-sm ml-3'>
                Has Influencer Recommended?
              </div>
            </div>
          </div>

          <div className={`mt-4 w-full md:w-4/5 ${hasRec ? "block" : "hidden"}`}>
            <InputField
              name='favourite'
              value={!editFlag ? " " : currentIdea?.content?.favourite}
              label='Favourite Places'
              req={false}
            />
          </div>
          <div className={`mt-4 w-full md:w-4/5 ${hasRec ? "block" : "hidden"}`}>
            <InputField
              name='skip_places'
              value={!editFlag ? " " : currentIdea?.content?.skip_places}
              label='Places to be Skipped'
              req={false}
            />
          </div>

          <div className={`mt-4 w-full md:w-4/5`}>
            <InputField
              name='extras'
              value={!editFlag ? " " : currentIdea?.content?.extras}
              label='Extra Perks of Trip'
              req={false}
            />
          </div>
          <div className={`mt-4 w-full md:w-4/5`}>
            <InputField
              name='influencers'
              value={!editFlag ? " " : currentIdea?.content?.influencers}
              label='Influencers Joining the Trip'
              req={false}
            />
          </div>
          <div className={`mt-4 w-full md:w-4/5`}>
            <InputField
              name='highlight'
              value={!editFlag ? " " : currentIdea?.content?.highlight}
              label='On this Bucket List'
              req={false}
            />
          </div>
          <div className={`mt-4 w-full md:w-4/5`}>
            <InputField
              name='places'
              value={!editFlag ? " " : currentIdea?.content?.places}
              label='Places to Visit'
              req={false}
            />
          </div>

          <div className="mt-4 w-full md:w-4/5">
            <label className='block text-sm font-medium text-gray-700'>
              Upload Collage Image
            </label>
            <DropZone onDrop={onDropExp1Image} files={exp1Image} />
          </div>

          <div className='mt-4 w-full md:w-4/5 hidden'>
            <InputField
              name='customize_itinerary_link'
              value={"https://chat.whatsapp.com/HeccccRC7cb445xAidrOdz"}
              label='Customise Trip Whatsapp Link'
              req={false}
            />
          </div>
          <h3 className='text-[20px] mt-4'>Unique Experience Section</h3>
          {/* Experiences Section */}
          <div className='mt-4 grid grid-cols-2 gap-6 w-full md:w-4/5'>
            <div className="col-span-1 hidden">
              <InputField
                name='exp1_title'
                value={!editFlag ? " " : currentIdea?.content?.experiences[0]?.title}
                label='Experience 1 Title'
                req={false}
              />
            </div>
            <div className="col-span-2">
              <InputField
                name='exp1_desc'
                value={!editFlag ? "" : currentIdea?.content?.experiences[0]?.description}
                label='Experience 1 Description'
                req={false}
              />
            </div>
            <div className="col-span-2 hidden">
              <label className='block text-sm font-medium text-gray-700'>
                Upload Experience 1 Image
              </label>
              <DropZone onDrop={onDropExp1Image} files={exp1Image} />
            </div>

            <div className="col-span-1 hidden">
              <InputField
                name='exp2_title'
                value={!editFlag ? " " : currentIdea?.content?.experiences[1]?.title}
                label='Experience 2 Title'
                req={false}
              />
            </div>
            <div className="col-span-2">
              <InputField
                name='exp2_desc'
                value={!editFlag ? "" : currentIdea?.content?.experiences[1]?.description}
                label='Experience 2 Description'
                req={false}
              />
            </div>
            <div className="col-span-2 hidden">
              <label className='block text-sm font-medium text-gray-700'>
                Upload Experience 2 Image
              </label>
              <DropZone onDrop={onDropExp2Image} files={exp2Image} />
            </div>

            <div className="col-span-1 hidden">
              <InputField
                name='exp3_title'
                value={!editFlag ? " " : currentIdea?.content?.experiences[2]?.title}
                label='Experience 3 Title'
                req={false}
              />
            </div>
            <div className="col-span-2">
              <InputField
                name='exp3_desc'
                value={!editFlag ? "" : currentIdea?.content?.experiences[2]?.description}
                label='Experience 3 Description'
                req={false}
              />
            </div>
            <div className="col-span-2 hidden">
              <label className='block text-sm font-medium text-gray-700'>
                Upload Experience 3 Image
              </label>
              <DropZone onDrop={onDropExp3Image} files={exp3Image} />
            </div>
          </div>

          <h3 className='text-[20px] mt-10'>Itineary</h3>
          <div className='mt-4 grid grid-cols-2 gap-6 w-full md:w-4/5'>
            <div className="col-span-1">
              <h3>Number of Itineary Days: </h3>
            </div>
            <div className="col-span-1">
              <InputField req={false} value={itinearyRows} type='number' min={0} name='' label="Days Count"
                onChangeHandler={(e) => {
                  setItinearyRows(e.target.value);
                }}
              />
            </div>
            {
              itinearyContent
            }
          </div>
          <h3 className='text-[20px] mt-10'>Inclusion & Exclusion</h3>
          <div className='mt-4 grid grid-cols-2 gap-6 w-full md:w-4/5'>
            <div className="col-span-2">
              <InputField
                name={`inclusions`}
                value={!editFlag ? "" : defaultIncl}
                placeholder="Use comma-seperated values(No spaces) Ex: Activity1.,Activity2"
                label={`Inclusions`}
                req={true}
              />
            </div>
            <div className="col-span-2">
              <InputField
                name={`exclusions`}
                value={!editFlag ? "" : defaultExcl}
                placeholder="Use comma-seperated values(No spaces) Ex: Activity1.,Activity2"
                label={`Enclusions`}
                req={true}
              />
            </div>
          </div>

          <h3 className='text-[20px] mt-10'>FAQs</h3>
          <div className='mt-4 grid grid-cols-2 gap-6 w-full md:w-4/5'>
            {Array.from({ length: faqCount }, (_, i) => {
              let len = 0;
              let q = "";
              let a = ""
              if (editFlag) {
                len = currentIdea?.content?.faqs?.length;
                if (len > 0 && len > i) {
                  q = currentIdea?.content?.faqs[i]?.question;
                  a = currentIdea?.content?.faqs[i]?.answer;
                }
              }
              return (
                <React.Fragment key={`faq_${i}`}>
                  <div className="col-span-2">FAQ {i + 1}</div>
                  <div className="col-span-1">
                    <InputField
                      name={`faq_q_${i + 1}`}
                      value={!editFlag ? "" : q}
                      label={`Question ${i + 1}`}
                      req={i === 0 ? true : false}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputField
                      name={`faq_a_${i + 1}`}
                      value={!editFlag ? "" : a}
                      label={`Answer ${i + 1}`}
                      req={i === 0 ? true : false}
                    />
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
            <div className='w-full md:w-full'>
              <button onClick={processClose} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                Cancel
              </button>
            </div>
            <div className='w-full md:w-full'>
              <button disabled={isSubmitting || createIdeaLoading || updateIdeaLoading} type='submit' className='bg-btnPink shadow-sidebarActive disabled:opacity-50 rounded-lg h-10 w-full flex justify-center items-center text-white' >
                {editFlag ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddIdeasModal;
