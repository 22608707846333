import React, { useState, Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import UpDownIcon from '../../assets/images/updownIcon.svg'

function SelectMenu({ label = "", name="", value = "", options = [], req, setValue = null }) {
    const [selectedOpt, setSelectedOpt] = useState("Select an Option..");
    useEffect(() => {
      if(value && value.length > 0){
        setSelectedOpt(value);
      }
    }, [])

    const onChange = (e) => {
        setSelectedOpt(e);
        if (setValue) {
            setValue(e);
        }
    }
    
    return (
        <div>
            <label id={label} className="block mb-1 text-sm font-medium text-gray-900">
                {label}
                {req && (
                    <span className="text-red-700">
                        *
                    </span>
                )}
            </label>

            <Listbox id="select-1" name={name} value={selectedOpt} onChange={onChange}>
                <div id='select-2' className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-3 text-left shadow-sidebarActive focus:outline-none border border-inputBorder focus-visible:ring-offset-gray-300 sm:text-sm">
                        <span
                            className={`block truncate ${selectedOpt === 'Select an Option..' && "text-xs text-gray-400"}`}
                        >
                            {selectedOpt.toUpperCase()}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <img src={UpDownIcon} alt="" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <Listbox.Option
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                    }`
                                }
                                value="Select an Option.."
                            >
                                <span
                                    className={`block truncate`}
                                >
                                    Select an Option..
                                </span>
                            </Listbox.Option>
                            {options.map((opt) => (
                                <Listbox.Option
                                    key={opt.label}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={opt.value}
                                >
                                    <span
                                        className={`block truncate`}
                                    >
                                        {opt.label}
                                    </span>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default SelectMenu