// import upIcon from '../../../assets/images/upIcon.svg';
import { useEffect, useState } from 'react';
import upIcon from '../../../../assets/images/upIcon.svg';
import {
  deleteIdeaFromDB,
  listAllIdeas,
  sortIdeasAlphabetically,
} from '../utils';
import filterLogo from '../../../../assets/images/filterLogo.svg';
import downloadLogo from '../../../../assets/images/downloadLogo.svg';
import createLogo from '../../../../assets/images/createLogo.svg';
import { classify } from '../../../../common/Utility';
import { String } from 'aws-sdk/clients/appstream';
import Pagination from '../../../../sharedComponents/Pagination/Pagination';
import PaginationNew from '../../../../sharedComponents/Pagination/PaginationNew';
import AddIdeasModal from './AddIdeasModal/AddIdeasModal';
import IdeasContentModal from '../IdeasContentModal';
import { useDispatch } from 'react-redux';
import { setCurrentIdea, setCurrentIdeaContent } from '../../../../api/bucketlistSlice';

const BucketListIdeas = ({
  allIdeas,
  allCategories,
  setAllIdeas,
}: {
  allIdeas: Idea[];
  allCategories: IdeaCategory[];
  setAllIdeas: (newIdeas: Idea[]) => void;
}) => {
  const dispatch = useDispatch();
  const [pageIdeas, setPageIdeas] = useState<Idea[]>([]);
  const [showAddIdeasModal, setShowAddIdeasModal] = useState(false);
  const [showIdeaContentModal, setIdeaContentModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  async function processDeleteIdea(ideaId: string) {
    deleteIdeaFromDB(ideaId).then((res) => {
      setAllIdeas(
        sortIdeasAlphabetically([
          ...allIdeas.filter((existingIdea) => existingIdea.idea_id !== ideaId),
        ])
      );
    });
  }

  const openContentModal = (e) => {
    setIdeaContentModal(true);
    console.log("EVENT", e);
    
    dispatch(setCurrentIdeaContent(e?.content));
  }

  const openEditModal = (e) => {
    setEditFlag(true);
    dispatch(setCurrentIdea(e));
    setShowAddIdeasModal(true);
  }

  const tableHeadingClasses =
    'text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading';
  const tableCellClasses =
    'min-h-[40px] flex items-center justify-start [&_*]:truncate';

  return (
    <div id="main-container" className="container max-w-full">
      <div className="w-full flex justify-between items-center min-w-max">
        <h1 className="text-[32px] font-bold m-[40px]">Ideas</h1>
        <div className="w-[200px] mr-10 flex justify-around">
          {/* <div className="cursor-pointer">
            <img onClick={() => {}} src={filterLogo} alt="" />
          </div>
          <div className="cursor-pointer">
            <img onClick={() => {}} src={downloadLogo} alt="" />
          </div> */}
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowAddIdeasModal(true);
            }}
          >
            <img src={createLogo} alt="" />
          </div>
        </div>
      </div>

      <div className="container max-w-full">
        <div className="flex min-w-[1200px]">
          {/* FIXED LHS SECTION OF TABLE */}
          <div className="flex flex-col h-full">
            <div className="flex mb-4">
              <div
                className={`text-sm text-darkGray font-bold min-h-[51px] flex items-center bg-tableHeading justify-start w-[30vw] lg:w-[20vw] ml-10 pl-5 rounded-l-lg`}
              >
                Name
                <img
                  onClick={() => {}}
                  // className={`ml-2 ${
                  //   ascSort[1] ? 'rotate-180' : ''
                  // } cursor-pointer`}
                  // src={upIcon}
                  alt=""
                />
              </div>
            </div>
            {/* Table Rows */}
            {pageIdeas.map((idea, i) => {
              return (
                <div className="flex mb-4" key={i}>
                  <div
                    onClick={() => openEditModal(idea)}
                    className={`justify-start items-center min-h-[100px] flex truncate w-[30vw] lg:w-[20vw] ml-10 pl-5`}
                  >
                    <div className="w-[95%] cursor-pointer overflow-auto underline">
                      {idea.idea_title}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* HORIZONTAL SCROLLING SECTION OF TABLE: */}
          <div className="flex flex-col sm:w-[700px] md:w-[850px]  overflow-x-auto">
            <div className="flex w-fit mb-4">
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[200px]`}
              >
                Content
              </div>
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[200px]`}
              >
                Categories
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                Description
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[200px]`}>
                Image URL
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[10vw]`}>
                Voucher Code
              </div>
              <div className={`${tableHeadingClasses} w-[35vw] lg:w-[15vw]`}>
                Merchant ID
              </div>

              {/* 'DELETE' button column: */}
              {/* <div
                className={classify([tableHeadingClasses, 'w-[200px]'])}
              ></div> */}
            </div>

            {/* ACTUAL DATA LINES: */}
            {pageIdeas.map((idea, i) => {
              return (
                <div className="flex w-fit mb-4" key={i}>
                  <div onClick={() => { openContentModal(idea) }} className={classify([tableCellClasses, 'w-[200px] underline cursor-pointer'])}>
                    View Itineary Content
                  </div>
                  <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span>
                      {(idea.categories ?? [])
                        .map((cat) => cat.category_name)
                        .join(', ')}
                    </span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[75vw] lg:w-[25vw] pr-4',
                    ])}
                  >
                    <span>{idea.idea_content}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[75vw] lg:w-[200px]',
                    ])}
                  >
                    <img className='max-h-[100px] w-full object-contain' src={idea.idea_image_url} alt="image" />
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      ' w-[75vw] lg:w-[10vw]',
                    ])}
                  >
                    <span>{idea.voucher_code}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[35vw] lg:w-[15vw]',
                    ])}
                  >
                    <span>{idea.merchant_id}</span>
                  </div>

                  {/* 'DELETE' button: */}
                  {/* <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span
                      className={classify([
                        'text-red-500 border border-red-500 p-1 text-sm cursor-pointer hover:bg-red-500 hover:text-white',
                      ])}
                      onClick={() => {
                        processDeleteIdea(idea.idea_id);
                      }}
                    >
                      DELETE
                    </span>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* PAGINATION AT BOTTOM: */}
      <PaginationNew
        type="Ideas"
        allFilteredData={allIdeas}
        setPageData={setPageIdeas}
      />

      {/* 'ADD IDEAS' MODAL: */}
      <AddIdeasModal
        show={showAddIdeasModal}
        allExistingIdeas={allIdeas}
        allExistingCategories={allCategories}
        setAllIdeas={setAllIdeas}
        editFlag={editFlag}
        closeModal={() => {
          setShowAddIdeasModal(false)
          setEditFlag(false);
        }}
      />

      {/* IDEA Content Modal */}
      <IdeasContentModal
        show={showIdeaContentModal}
        closeModal={() => setIdeaContentModal(false)}
      />
    </div>
  );
};

export default BucketListIdeas;
