// @ts-nocheck
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import BucketListCategories from './Categories/BucketListCategories';
import BucketListIdeas from './Ideas/BucketListIdeas';
import { classify } from '../../../common/Utility';
import {
  deleteIdeaFromDB,
  listAllCategories,
  listAllIdeas,
  sortCategoriesAlphabetically,
  sortIdeasAlphabetically,
} from './utils';
import { useSelector, useDispatch } from 'react-redux';
import { listAllCategoriesAPI, listAllIdeasAPI } from '../../../api/bucketlistSlice';

const BucketList = () => {
  const dispatch = useDispatch();
  const [pageSelected, setPageSelected] = useState<'Categories' | 'Ideas'>(
    'Ideas'
  );

  const { ideas, listIdeasLoading, categories, listCategoriesLoading } = useSelector(state => state.bucketList);

  const [allCategories, setAllCategories] = useState<IdeaCategory[]>([]);
  const [allIdeas, setAllIdeas] = useState<Idea[]>([]);

  // Fetch initial data:
  useEffect(() => {
    if(!categories || (categories && categories.length === 0)) {
      getAllCategories();
    }
    if(!ideas || (ideas && ideas.length === 0)) {
      console.log("INSIDE IF");
      
      getAllIdeas();
    }
    console.log("IDEAS", ideas)
  }, []);

  const getAllIdeas = () => {
    dispatch(listAllIdeasAPI())
  }

  const getAllCategories = () => {
    dispatch(listAllCategoriesAPI());
  }

  return (
    <div>
      {(listIdeasLoading || listCategoriesLoading) ? (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <TailSpin
            height="120"
            width="120"
            color="#62A6FF"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : (
        <>
          <BucketListIdeas
            allIdeas={ideas}
            allCategories={categories}
            setAllIdeas={setAllIdeas}
          />
        </>
      )}
    </div>
  );
};
export default BucketList;
